/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Iceweasel es como llaman al Firefox en Debian etch y posteriores debido a un problema de licencias. El caso es que en Iceweasel no se puede instalar la barra de google así como así, pero con este pequeño truquito lo podemos hacer enseguida."), "\n", React.createElement(_components.p, null, "- Primero vamos a la página ", React.createElement(_components.strong, null, "about:config,"), " la pagina de configuracion de Firefox o Iceweasel (Que en realidad es lo mismo)."), "\n", React.createElement(_components.p, null, "- Luego buscamos la opción ", React.createElement(_components.strong, null, "general.useragent.extra.firefox"), " y clicamos en ella."), "\n", React.createElement(_components.p, null, "- Pondrá el nombre y la versión de Iceweasel, solo tenemos que cambiar ", React.createElement(_components.strong, null, "Iceweasel"), " por ", React.createElement(_components.strong, null, "Firefox"), " y google se creera que estamos navegando por Firefox."), "\n", React.createElement(_components.p, null, "Ahora ya podemos bajarnos e instalarnos la toolbar de google desde ", React.createElement(_components.a, {
    href: "http://www.google.com/tools/firefox/toolbar/FT3/intl/es/index.html"
  }, "aquí.")), "\n", React.createElement(_components.p, null, "La verdad es que a la barra le veo poca utilidad pero a veces de algo sirve, normalmente prefiero dejarla oculta, me gusta tener espacio en mi navegador."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
